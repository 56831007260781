import { Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { FirebaseOptions } from 'angularfire2';
import { environment } from '../../environments/environment';
import { FirebaseService } from './firebase.service';
import { AngularFireStorage } from '@angular/fire/storage';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class AngularfirestoreCoolStoreService extends AngularFirestore { }

const settings = {};

export function AngularfirestoreCoolStoreFactory(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.coolstore, 'coolstore', false, null, platformId, zone, settings);
}

export class AngularfirestorePerfectStoreService extends AngularFirestore { }

export function AngularfirestorePerfectStoreFactory(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.perfectstore, 'perfectstore', false, null, platformId, zone, settings);
}

export class AngularfirestoreCoolStorageService extends AngularFireStorage { }

export function AngularfirestoreCoolStorageFactory(platformId: Object, zone: NgZone) {
  return new AngularFireStorage(environment.coolstore, 'coolStorage', "gs://mona-ai-pwa-firebase.appspot.com/", platformId, zone);
}

export class AngularfirestoreCustomerService extends AngularFireStorage { }

export function AngularfirestoreCustomerFactory(platformId: Object, zone: NgZone, bucket) {
  return new AngularFireStorage(environment.coolstore, 'customerStorage', bucket, platformId, zone);
}

