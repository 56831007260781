// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  googleMapsApiKey: 'AIzaSyA9eg8e7FW-cgh-jvB8uEwFBtBmTBL3sSk',
  production: false,
  server: "https://mona-ai.online:8599",
  firebase: {
    apiKey: "AIzaSyBj4xsSP9AojxvFI6s_329reBdxuRxIja4",
    authDomain: "mona-ai-pwa-firebase.firebaseapp.com",
    projectId: "mona-ai-pwa-firebase",
    storageBucket: "mona-ai-pwa-firebase.appspot.com",
    messagingSenderId: "219198639425",
    appId: "1:219198639425:web:809db39647f61021d565c0"
  },
  coolstore: {
    apiKey: "AIzaSyBj4xsSP9AojxvFI6s_329reBdxuRxIja4",
    authDomain: "mona-ai-pwa-firebase.firebaseapp.com",
    projectId: "mona-ai-pwa-firebase",
    storageBucket: "mona-ai-pwa-firebase.appspot.com",
    messagingSenderId: "219198639425",
    appId: "1:219198639425:web:809db39647f61021d565c0"
  },
  perfectstore: {
    apiKey: "AIzaSyDj9-imxQz-cI4WGi-NJq2jZDprXnhk9eE",
    authDomain: "mona-plattform.firebaseapp.com",
    projectId: "mona-plattform",
    storageBucket: "mona-plattform.appspot.com",
    messagingSenderId: "922760807427",
    appId: "1:922760807427:web:c21e0d18244fa96dbf3a36",
    measurementId: "G-2TZK54JBJD"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
