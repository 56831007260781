import { Component, PLATFORM_ID, NgZone, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
  AngularfirestoreCoolStoreService,
  AngularfirestorePerfectStoreService,
} from './services/angularfirestore-extension.service'
import { Observable } from 'rxjs';
import { FirebaseService } from './services/firebase.service';
const customer = "kunde1001";

import { AngularFirestore } from 'angularfire2/firestore';
import { CustomerdatabaseService } from './services/customerdatabase.service';
import { ProfileService } from './services/profile.service';
import { TutorialService } from './services/tutorial.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { TranslationObject } from './helper/TranslationObject';
import { MenuService } from './services/menu-service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  logo = "../assets/mona-logo-neu.png"
  public loginFinished = false;
  ProfileIcon = "";
  coolClctn$: Observable<any>
  perfectClctn$: Observable<any>
  currentUser: any;
  intern = false;
  isAuthenticated: boolean = true;

  translationObject: any;

  public appPages = [];
  // [
  //   { title: 'Dashboard', url: '/dashboard', photo: '../assets/icon/dashboard.png' },
  //   // { title: "MONA erstellen", url: '/flow', photo: "../assets/icon/wizard.png" },
  //   { title: 'Stellenanzeigen', url: '/job-offers', photo: '../assets/icon/joboffers.png' },
  //   { title: 'Kandidaten', url: '/applicants', photo: '../assets/icon/candidates.png' },
  //   // { title: 'Einstufungstest', url: '/assessment-tests', photo: '../assets/flaticons/check-list.png' },
  //   { title: 'Kommunikation', url: '/mona-communication-list', photo: '../assets/flaticons/negotiation.png' },
  //   // { title: 'Kampagnen', url: '/campaigns', photo: '../assets/icon/campaign.png' },
  //   // { title: 'Terminverwaltung', url: '/appointments', photo: '../assets/icon/deadline.png' },
  //   { title: 'Insights', url: '/insights', photo: '../assets/flaticons/insights.png' },
  //   { title: 'Einstellungen', url: '/settings', photo: '../assets/flaticons/settings.png' },
  //   // { title: 'Kommunikation', url: '/communication-dashboard', photo: '../assets/flaticons/bullhorn.png' },
  //   // { title: 'Teamverwaltung', url: '/team-management', photo: '../assets/flaticons/manager.png' },
  // ];

  // public appPages2 = [];
  // [
  //   { title: 'Dashboard', url: '/dashboard', photo: '../assets/icon/dashboard.png' },
  //   // { title: "MONA erstellen", url: '/flow', photo: "../assets/icon/wizard.png" },
  //   { title: 'Stellenanzeigen', url: '/job-offers', photo: '../assets/icon/joboffers.png' },
  //   { title: 'Kandidaten', url: '/applicants', photo: '../assets/icon/candidates.png' },
  //   // { title: 'Kampagnen', url: '/campaigns', photo: '../assets/icon/campaign.png' },
  //   // { title: 'Terminverwaltung', url: '/appointments', photo: '../assets/icon/deadline.png' },
  //   // { title: 'Insights', url: '/insights', photo: '../flaticons/icon/insights.png' },
  //   // { title: 'Flows', url: '/flow', photo: '../assets/icon/analysis.png' },
  //   { title: 'Kommunikation', url: '/communication-dashboard', photo: '../assets/flaticons/bullhorn.png' },
  //   { title: 'Teamverwaltung', url: '/team-management', photo: '../assets/flaticons/manager.png' },
  //   { title: 'SalesEmails', url: '/automations/email', photo: '../assets/flaticons/manager.png' },
  // ];
  // title here is the key of the translation object. Real title comes from the translation object
  public personalButtons = [
    // { title: "WasGibtsNeues", url: '/news', photo: '../assets/icon/megaphone-outline.png' },
    // { title: "NeustartTutorial", url: '/dashboard', photo: '../assets/icon/tutorial.png' },
    // { title: 'HowToVideos', url: '/howto', photo: '../assets/icon/play-skip-forward-outline.png' },
    // { title: 'Hilfe', url: '/help', photo: '../assets/icon/help-circle-outline.png' },
    { title: 'AccountEinstellungen', url: '/profile', photo: '../assets/icon/settings-outline.png' },
  ];
  //
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  @ViewChildren('itemRef', { read: ElementRef }) itemRefs: QueryList<ElementRef>;

  ngAfterViewInit() {
    // Access the item elements through itemRefs
    this.itemRefs.forEach(itemRef => {
      if (itemRef.nativeElement.innerText == "Stellenanzeigen") {
        const currentPage = this.router.url;
        const node = itemRef.nativeElement;
        this.authService.setData(node);
        console.log(node)
      }
    });
    this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      this.cdr.detectChanges();
    });
  }

  constructor(
    private profileService: ProfileService,
    private tutorialService: TutorialService,
    private router: Router,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private menuService: MenuService,

  ) {
    this.translationObject = TranslationObject.TRANSLATION_OBJECT;
    this.menuService.menuItems$.subscribe((items) => {
      this.appPages = items;
    })
  }

  // ngOnInit() {
  // }

  restartTutorial(data) {
    if (data.target.innerText == "Neustart-Tutorial") {
      const currentUrl = '/dashboard';
      this.router.navigateByUrl('/', { skipLocationChange: true }).then((completed) => {
        //if it is canDeactivateGuard returns true
        if (completed) {
          var uid = this.profileService.getCurrentUser().uid;
          this.authService.updateIsFirstLoginFlagToTrue(uid);
          this.router.navigate([currentUrl]);
        }
        //canDeactivateGuard returns false 
        else {
          return;
        }
      })

    }
  }


  getCurrentUser() {
    var currentUser = this.profileService.getCurrentUser();
    this.currentUser = currentUser;
    console.log(this.currentUser);
    if (this.currentUser.email != "admin@mona-ai.de") {
      this.appPages.splice(1, 6)
    }

    console.log(currentUser);
  }

  navigateToFeedbackUrl() {
    window.open("https://www.mona-ai.de/kontakt");
  }

  backToDashboard() {
    this.router.navigateByUrl("/dashboard");
  }
}


