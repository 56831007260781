import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  canActivate(): boolean {
    const url = window.location.href;
    if (this.authService.isLoggedIn()) {
      return true;
    } else if (url.includes('teammember')) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
