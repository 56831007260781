import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface MenuItem {
    title: string;
    url: string;
    icon?: string;
    photo?: string;
}

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private menuItemsSource = new BehaviorSubject<MenuItem[]>([]);
    menuItems$ = this.menuItemsSource.asObservable();

    isIntern = false;

    public appPages = [
        { title: 'Dashboard', url: '/dashboard', photo: '../assets/icon/dashboard.png' },
        { title: 'Interviews', url: '/job-offers', photo: '../assets/icon/joboffers.png' },
        { title: 'Kandidaten', url: '/applicants', photo: '../assets/icon/candidates.png' },
        { title: 'Kommunikation', url: '/mona-communication-list', photo: '../assets/flaticons/negotiation.png' },
        { title: 'Insights', url: '/insights', photo: '../assets/flaticons/insights.png' },
        { title: 'Einstellungen', url: '/settings', photo: '../assets/flaticons/settings.png' },
    ];

    public appPages2 = [
        { title: 'Dashboard', url: '/dashboard', photo: '../assets/icon/dashboard.png' },
        { title: 'Interviews', url: '/job-offers', photo: '../assets/icon/joboffers.png' },
        { title: 'Kandidaten', url: '/applicants', photo: '../assets/icon/candidates.png' },
        { title: 'Kommunikation', url: '/communication-dashboard', photo: '../assets/flaticons/bullhorn.png' },
        { title: 'Teamverwaltung', url: '/team-management', photo: '../assets/flaticons/manager.png' },
        { title: 'SalesEmails', url: '/automations/email', photo: '../assets/flaticons/manager.png' },
    ];

    constructor() {
        if (this.isIntern) {
            this.setMenuItems(this.appPages2);
        } else {
            this.setMenuItems(this.appPages);
        }
    }

    addMenuItem(newItem: MenuItem) {
        const currentItems = this.menuItemsSource.value;
        this.menuItemsSource.next([...currentItems, newItem]);
    }

    setMenuItems(items: MenuItem[]) {
        this.menuItemsSource.next(items);
    }

}