import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';
import { Subject } from 'rxjs';
import { TutorialPopoverPage } from '../tutorial-popover/tutorial-popover.page';
import { AuthService } from './auth.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {
  isUpArrow = true;
  tutorialCompleted = false;
  private dataFilledIn = new Subject();
  tutorialSteps = [
    {
      page: '/dashboard',
      target: '.step-1',
      content: 'Klicken Sie auf die Stellenanzeigen'
    },
    {
      page: '/job-offers',
      target: '.step-2',
      content: 'Klicken Sie auf die Stellenanzeige erstellen'
    },
    {
      page: '/new-job-offer',
      target: '.step-3',
      content: 'Geben Sie den Stellentitel ein'
    },
    {
      page: '/new-job-offer',
      target: '.step-4',
      content: 'Wählen Sie einen Avatar aus der Liste'
    },
    {
      page: '/new-job-offer',
      target: '.step-5',
      content: 'Fügen Sie die gewünschten Nachrichten, benutzerdefinierten Nachrichten und Sprachen hinzu und klicken Sie auf Veröffentlichen!!!'
    }
    // ...
  ];
  pageUrl: any;
  myEvent: any;

  constructor(private popoverController: PopoverController, private authService: AuthService, private profileService: ProfileService) {


  }



  async presentPopover(step, myEvent) {
    this.myEvent = myEvent;
    const popover = await this.popoverController.create({
      component: TutorialPopoverPage,
      componentProps: {
        content: step.content,
        someData: this.isUpArrow
      },
      event: myEvent,
      translucent: true,
      mode: 'ios',

      cssClass: 'my-custom-class',
    });

    popover.present().then((data) => {
      console.log(data);
      //const popoverElement = document.getElementsByClassName('my-custom-class')[0];
      // switch (step.target) {
      //   case '.step-1':
      //     popoverElement.setAttribute('style', 'margin-left: -44em; top: -14em;'
      //     );
      //     break;
      //   case '.step-2':
      //     popoverElement.setAttribute('style', 'margin-left: 1em; top: -21em;');
      //     break;
      //   case '.step-3':
      //     popoverElement.setAttribute('style', 'margin-left: 0em; top: -3em;');
      //     break;
      //   case '.step-4':
      //     popoverElement.setAttribute('style', 'margin-left: -32em; top: -8em;');
      //     break;
      //   case '.step-5':
      //     popoverElement.setAttribute('style', 'margin-left: -33em; top: -5.5em;');
      //     break;

      //   case '.step-6':
      //     popoverElement.setAttribute('style', 'margin-left: -33em; top: -5.5em;');
      //     break;
      //   case '.step-7':
      //     popoverElement.setAttribute('style', 'margin-left: -33em; top: -5.5em;');
      //     break;

      //   default:
      //     break;
      // }
      this.speakContent(step.content);
    });
    return popover;
  }

  async displayTutorialForPage(page, myEvent) {

    const steps = this.tutorialSteps.filter(step => step.page === page);
    let currentStepIndex = 0;
    let currentPopover = null;

    while (currentStepIndex < steps.length) {
      const step = steps[currentStepIndex];
      if (step.target === '.step-7' || step.target === '.step-5') {
        this.isUpArrow = false;
      } else {
        this.isUpArrow = true;
      }
      if (currentPopover) {
        await currentPopover.dismiss();
      }

      const popover = await this.presentPopover(step, myEvent);
      currentPopover = popover;

      await new Promise<void>(resolve => {
        this.dataFilledIn.subscribe((data: any) => {
          this.myEvent = data;
          myEvent = this.myEvent;
          resolve();
        });
      });
      currentStepIndex++;
    }

    if (currentPopover) {
      await currentPopover.dismiss();
    }
    var uid = this.profileService.getCurrentUser().uid;
    this.authService.updateIsFirstLoginFlag(uid);

  }

  emitDataFilledIn(evt: any) {
    this.dataFilledIn.next(evt);
  }

  speakContent(text) {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = synth.getVoices()[3];
    utterance.rate = 1;

    synth.speak(utterance);
  }
}
