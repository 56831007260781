import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-tutorial-popover',
  templateUrl: './tutorial-popover.page.html',
  styleUrls: ['./tutorial-popover.page.scss'],
})
export class TutorialPopoverPage implements OnInit {
  @Input() content: string;
  @ViewChild('popover') popover;
  someData: string;
  isUpArrow = true
  isOpen = false;
  constructor(private navParams: NavParams, private popoverController: PopoverController) {
    this.isUpArrow = this.navParams.get('someData');
   }

  ngOnInit() {
    this.isOpen = true;
  }

  async closePopover() {
    await this.popoverController.dismiss();
  }

}
