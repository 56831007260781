import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)

    // redirectTo: 'login',
    // pathMatch: 'full'
  },
  {
    path: 'flow',
    loadChildren: () => import('./flow/flow.module').then(m => m.FlowPageModule)
  },
  {
    path: 'automations',
    loadChildren: () => import('./automations/automations.module').then(m => m.AutomationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'artifical-intelligence-generation',
    loadChildren: () => import('./artifical-intelligence-generation/artifical-intelligence-generation.module').then(m => m.ArtificalIntelligenceGenerationPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'password',
    loadChildren: () => import('./password/password.module').then(m => m.PasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'email',
    loadChildren: () => import('./email/email.module').then(m => m.EmailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./delete-account/delete-account.module').then(m => m.DeleteAccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'deactivate-account',
    loadChildren: () => import('./deactivate-account/deactivate-account.module').then(m => m.DeactivateAccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'delete-data',
    loadChildren: () => import('./delete-data/delete-data.module').then(m => m.DeleteDataPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logo',
    loadChildren: () => import('./logo/logo.module').then(m => m.LogoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'videos',
    loadChildren: () => import('./videos/videos.module').then(m => m.VideosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'communication',
    loadChildren: () => import('./disposition/communication/communication.module').then(m => m.CommunicationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'applicants',
    loadChildren: () => import('./recruiting/applicants/applicants.module').then(m => m.ApplicantsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'applicant-information',
    loadChildren: () => import('./recruiting/applicant-information/applicant-information.module').then(m => m.ApplicantInformationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'applicant-information/:id',
    loadChildren: () => import('./recruiting/applicant-information/applicant-information.module').then(m => m.ApplicantInformationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'applicant-create-short-information',
    loadChildren: () => import('./recruiting/applicant-create-short-information/applicant-create-short-information.module').then(m => m.ApplicantCreateShortInformationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-request',
    loadChildren: () => import('./disposition/customer-request/customer-request.module').then(m => m.CustomerRequestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-requests',
    loadChildren: () => import('./disposition/customer-requests/customer-requests.module').then(m => m.CustomerRequestsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-request-create',
    loadChildren: () => import('./disposition/customer-request-create/customer-request-create.module').then(m => m.CustomerRequestCreatePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-job-offer',
    loadChildren: () => import('./job-offer/new-job-offer/new-job-offer.module').then(m => m.NewJobOfferPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'job-offers',
    loadChildren: () => import('./job-offer/job-offers/job-offers.module').then(m => m.JobOffersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'job-offer-information',
    children: [
      {
        path: '',
        loadChildren: () => import('./job-offer/job-offer-information/job-offer-information.module').then(m => m.JobOfferInformationPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':id',
        loadChildren: () => import('./job-offer/job-offer-information/job-offer-information.module').then(m => m.JobOfferInformationPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'applicant-information/:id',
        loadChildren: () => import('./recruiting/applicant-information/applicant-information.module').then(m => m.ApplicantInformationPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'job-offer-information/:id',
    loadChildren: () => import('./job-offer/job-offer-information/job-offer-information.module').then(m => m.JobOfferInformationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'delete-job-offer',
    loadChildren: () => import('./job-offer/delete-job-offer/delete-job-offer.module').then(m => m.DeleteJobOfferPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contracts',
    loadChildren: () => import('./contract/contracts/contracts.module').then(m => m.ContractsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-contract',
    loadChildren: () => import('./contract/new-contract/new-contract.module').then(m => m.NewContractPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contract-information/:id',
    loadChildren: () => import('./contract/contract-information/contract-information.module').then(m => m.ContractInformationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./campaign/campaigns/campaigns.module').then(m => m.CampaignsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'create-campaign',
    loadChildren: () => import('./campaign/create-campaign/create-campaign.module').then(m => m.CreateCampaignPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appointments',
    loadChildren: () => import('./appointment/appointments/appointments.module').then(m => m.AppointmentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'create-appointment',
    loadChildren: () => import('./appointment/create-appointment/create-appointment.module').then(m => m.CreateAppointmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appointment-information',
    loadChildren: () => import('./appointment/appointment-information/appointment-information.module').then(m => m.AppointmentInformationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign-information',
    loadChildren: () => import('./appointment/campaign-information/campaign-information.module').then(m => m.CampaignInformationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'communication-dashboard',
    loadChildren: () => import('./communication/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'team-management',
    loadChildren: () => import('./team-management/team-management.module').then(m => m.TeamManagementPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer/:customerId/teammember/:teammemberId/kid/:kidId',
    loadChildren: () => import('./signup/create-password/create-password.module').then(m => m.CreatePasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'create-workflow',
    loadChildren: () => import('./communication/create-workflow/create-workflow.module').then(m => m.CreateWorkflowPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-workflow',
    loadChildren: () => import('./communication/edit-workflow/edit-workflow.module').then(m => m.EditWorkflowPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-workflow/:[id,name,description,type,action,create]',
    loadChildren: () => import('./communication/edit-workflow/edit-workflow.module').then(m => m.EditWorkflowPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'automations/email',
    loadChildren: () => import('./automations/email/email.module').then(m => m.EmailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'preview-file/:applicantId/:id',
    loadChildren: () => import('./preview-file/preview-file.module').then(m => m.PreviewFilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then(m => m.NewsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'howto',
    loadChildren: () => import('./howto/howto/howto.module').then(m => m.HowtoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'create-howto',
    loadChildren: () => import('./howto/create-howto/create-howto.module').then(m => m.CreateHowtoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'upload',
    loadChildren: () => import('./upload/upload.module').then(m => m.UploadPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'integrations',
    loadChildren: () => import('./integrations/integrations.module').then(m => m.IntegrationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'conditional-flowchart',
    loadChildren: () => import('./conditional-flowchart/conditional-flowchart.module').then(m => m.ConditionalFlowchartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-nonde-popover',
    loadChildren: () => import('./add-node-popover/add-node-popover.module').then(m => m.AddNodePopoverPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tutorial-popover',
    loadChildren: () => import('./tutorial-popover/tutorial-popover.module').then(m => m.TutorialPopoverPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tutorial-intro',
    loadChildren: () => import('./tutorial-intro/tutorial-intro.module').then(m => m.TutorialIntroPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'video-modal',
    loadChildren: () => import('./video-modal/video-modal.module').then(m => m.VideoModalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'matching',
    children: [
      {
        path: '',
        loadChildren: () => import('./matching/matching.module').then(m => m.MatchingPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'applicant-information/:id',
        loadChildren: () => import('./recruiting/applicant-information/applicant-information.module').then(m => m.ApplicantInformationPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'blacklist',
    loadChildren: () => import('./blacklist/blacklist.module').then(m => m.BlacklistPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'update-all-job-offers',
    loadChildren: () => import('./job-offer/update-all-job-offers/update-all-job-offers.module').then(m => m.UpdateAllJobOffersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-message-modal',
    loadChildren: () => import('./edit-message-modal/edit-message-modal.module').then(m => m.EditMessageModalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-message-modal-for-new-job-offer-page',
    loadChildren: () => import('./edit-message-modal-for-new-job-offer-page/edit-message-modal-for-new-job-offer-page.module').then(m => m.EditMessageModalForNewJobOfferModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'get-data-for-ai',
    loadChildren: () => import('./ai-studio/get-data-for-ai/get-data-for-ai.module').then(m => m.GetDataForAIPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'set-dispo-money-popover',
    loadChildren: () => import('./dashboard/set-dispo-money-popover/set-dispo-money-popover.module').then(m => m.SetDispoMoneyPopoverPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'create-custom-video',
    loadChildren: () => import('./howto/create-custom-video/create-custom-video.module').then(m => m.CreateCustomVideoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reporting',
    loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-assessment-test',
    loadChildren: () => import('./assessment-test/new-assessment-test/new-assessment-test.module').then(m => m.NewAssessmentTestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'assessment-tests',
    loadChildren: () => import('./assessment-test/assessment-tests/assessment-tests.module').then(m => m.AssessmentTestsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mona-communication-list',
    loadChildren: () => import('./mona-communication/mona-communication-list/mona-communication-list.module').then(m => m.MonaCommunicationListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'assessment-test-information/:id',
    loadChildren: () => import('./assessment-test/assessment-test-information/assessment-test-information.module').then(m => m.AssessmentTestInformationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'insights',
    children: [
      // {
      //   path: "",
      //   loadChildren: () => import('./insights/insights/insights.module').then(m => m.InsightsPageModule)
      // },
      {
        path: "",
        loadChildren: () => import('./insights/reporting/reporting.module').then(m => m.ReportingPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: "performance",
        loadChildren: () => import('./insights/performance/performance.module').then(m => m.PerformancePageModule),
        canActivate: [AuthGuard]
      },
      {
        path: "analytics",
        loadChildren: () => import('./insights/analytics/analytics.module').then(m => m.AnalyticsPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: "reporting",
        loadChildren: () => import('./insights/reporting/reporting.module').then(m => m.ReportingPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: "interviews",
        loadChildren: () => import('./insights/interviews/interviews.module').then(m => m.InterviewsPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: "conversations",
        loadChildren: () => import('./insights/conversations/conversations.module').then(m => m.ConversationsPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'contact-form',
    loadChildren: () => import('./contact-form/contact-form.module').then(m => m.ContactFormPageModule)
  },
  {
    path: 'data-warehouse',
    loadChildren: () => import('./data-warehouse/data-warehouse.module').then(m => m.DataWarehousePageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
